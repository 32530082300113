import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './features.module.scss';

const RelationsPage = props => {
    const { t } = useTranslation();

    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="relations-page:pageTitle">Relations - Knowledge Graph</Trans>}
            subtitle={<Trans i18nKey="relations-page:pageSubtitle">Unleash the Efficiency of Intelligent Document
                                                                   Connections</Trans>}
            cover="relations.jpg"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="relations-page:pageDescription">
                <p>Utilizing state-of-the-art technology, AIDA's <strong>document
                                                                         relation</strong> feature <strong>automatically</strong> identifies
                   and links relevant documents based on field values or full-text content.</p>
                <p>This powerful solution allows you to create relation chains of <strong>any length</strong>,
                   involving <strong>multiple document types</strong>, significantly enhancing your document management
                   process.</p>
                <p>By generating a comprehensive <strong>knowledge graph</strong>, AIDA enables your business to access
                   vital information quickly and accurately, streamlining your workflow and promoting <strong>data-driven
                                                                                                              decisions</strong>.
                </p>
            </Trans>
        </div>
        <div className={classes.FeaturesContainer}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/relations-knowledge-graph/aida-relations-knowledge-graph-01-relations-browsing.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('relations-page:pageTitle')}</div>
                            <h2><Trans>Experience revolutionary document browsing with AIDA's relation-based
                                       navigation</Trans></h2>
                            <Trans i18nKey="relations-page:feature1">
                                <p>AIDA's relation-based document browsing is a <strong>game-changer</strong> in the
                                   world of document management.</p>
                                <p>By focusing on the <strong>relationships between documents</strong>, AIDA completely
                                   redefines the way users navigate and access information, making it significantly more
                                   efficient and user-friendly compared to traditional folder-based systems.</p>
                                <p>Take, for example, an organization that deals with a high volume of invoices,
                                   purchase orders, and contracts.</p>
                                <p>In a conventional folder-based system, these documents might be stored in separate
                                   folders, making it difficult and time-consuming to locate and analyze the
                                   relationships between them.
                                   With AIDA's relation-based browsing, you can <strong>easily navigate</strong> from an
                                   invoice to its corresponding purchase order and contract, providing a <strong>clear
                                                                                                                 overview</strong> of
                                   the interconnected data.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('relations-page:pageTitle')}</div>
                            <h2><Trans>Uncover valuable connections between documents</Trans></h2>
                            <Trans i18nKey="relations-page:feature2">
                                <p>Discover the <strong>next generation of document management</strong> with AIDA's
                                   document relation feature, which intelligently connects documents based on extracted
                                   field values, full-text OCR content, or a manual link.</p>
                                <p>This groundbreaking functionality enables the creation of knowledge graphs that
                                   accurately represent <strong>real-world business workflows</strong>, steps, and
                                   timing requirements, providing unprecedented insight into your organization's
                                   information flow.</p>
                                <p>Unleash the full potential of your documents and optimize your business processes
                                   with AIDA's document relation feature, which streamlines document management and
                                   harnesses the power of knowledge graphs for more informed decision-making.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/relations-knowledge-graph/aida-relations-knowledge-graph-02-document-connections.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/relations-knowledge-graph/aida-relations-knowledge-graph-03-relations-chains-workflow.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('relations-page:pageTitle')}</div>
                            <h2><Trans>Optimize workflows with AIDA's relation chains</Trans></h2>
                            <Trans i18nKey="relations-page:feature3">
                                <p>Experience <strong>unparalleled flexibility</strong> and customization with AIDA's
                                   document relation feature, which allows you to create relation chains of any length
                                   and involving any number of document types.</p>
                                <p>This adaptability ensures seamless integration into your existing business
                                   workflows, <strong>accurately reflecting</strong> each step and their timing
                                   requirements for a more efficient document management experience.</p>
                                <p>Maximize the value of your documents and enhance your organization's workflows with
                                   AIDA's versatile relation chains, which enable you to establish <strong>meaningful
                                                                                                           connections</strong> between
                                   documents based on their content or extracted field values.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <div className={classes.SmallTitle}>{t('relations-page:pageTitle')}</div>
                            <h2><Trans>Complete performance and scalability with AIDA's state-of-the-art graph
                                       database</Trans></h2>
                            <Trans i18nKey="relations-page:feature4">
                                <p>AIDA's document relation feature is powered by a state-of-the-art <strong>specialized
                                                                                                             Graph
                                                                                                             database</strong>,
                                   providing exceptional performance and scalability when managing complex document
                                   relationships.</p>
                                <p>The use of a Graph database for managing document relations offers <strong>significant
                                                                                                              advantages</strong> over
                                   traditional relational (SQL-based) or non-relational (NoSQL) databases:
                                   while these rely on predefined structures and tables, Graph databases excel in
                                   handling complex, interconnected data by storing relationships as first-class
                                   entities.</p>
                                <p>This flexibility allows AIDA to map <strong>intricate document
                                                                               relationships</strong> and workflows more
                                   naturally, leading to faster query performance and greater scalability.</p>
                                <p>By embracing the potential of Graph database technology, AIDA <strong>empowers your
                                                                                                         organization</strong> to
                                   optimize document management processes and uncover valuable insights across various
                                   business workflows.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/relations-knowledge-graph/aida-relations-knowledge-graph-04-graph-database.jpg"
                            width={901}
                            height={666}
                            alt="AIDA"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default RelationsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "relations-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'relations-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Relations - Knowledge Graph';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
